<template>
  <b-modal
    id="hospital-create-modal"
    title="Hospital Create Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Hospital Create</h2>
    </template>
    <validation-observer ref="hospitalCreateFormValidation">
      <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
          <template #label>
            Hospital Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            placeholder="Hospital Name"
            required
            key="name"
            @keydown="checkMaxLength($event, key, 200)"
          />
        </b-form-group>

        <b-form-group label-for="location">
          <template #label>
            Location <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            id="location"
            v-model="location"
            placeholder="Location"
            rows="3"
            max-rows="6"
            required
            key="location"
            @keydown="checkMaxLength($event, key, 1000)"
          ></b-form-textarea>
        </b-form-group>
        <!-- v-restrict="regex2" -->
        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
            required
          ></b-form-select>
        </b-form-group>

        <validation-provider v-slot="{ errors }" name="Image" rules="required">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
            <template #label>
              Image <span class="text-danger">*</span>
            </template>
            <b-form-file
              ref="imageInput"
              id="documents"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              required
              plain
              accept=".png,.jpg,.jpeg"
              v-model="files"
              @change="validateFileType($event, 'files')"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <validation-provider
          #default="{ errors }"
          name="DisplayImage"
          rules="required"
        >
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="displayDocuments"
            description="Upload an banner image in PNG, JPG, or JPEG format."
          >
            <template #label>
              Banner Image <span class="text-danger">*</span>
            </template>
            <b-form-file
              ref="displayImageInput"
              id="displayDocuments"
              placeholder="Choose a file or drop it here..."
              drop-placeholder="Drop file here..."
              required
              accept=".png,.jpg,.jpeg"
              plain
              v-model="displayFiles"
              @change="validateFileType($event, 'displayFiles')"
            ></b-form-file>

            <p class="error">{{ errors[0] }}</p>
          </b-form-group>
        </validation-provider>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="primary" pill class="mr-1">
            Save
          </b-button>
          <b-button
            type="reset"
            variant="outline-secondary"
            @click="reset"
            pill
          >
            Reset
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  data() {
    return {
      required,
      name: "",
      location: "",
      description: "",
      displayFiles: null,
      files: null,
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  methods: {
    ...mapActions({
      createHospital: "appData/createHospital",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (fileType === "files") {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          event.target.value = null;
          if (this.$refs.imageInput) {
            this.$refs.imageInput.$el.setCustomValidity(
              "Please upload a PNG, JPG, or JPEG image file."
            );
            this.$refs.imageInput.$el.reportValidity();
          }
        } else {
          if (this.$refs.imageInput) {
            this.$refs.imageInput.$el.setCustomValidity("");
          }
        }
      } else if (fileType === "displayFiles") {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          event.target.value = null;
          if (this.$refs.displayImageInput) {
            this.$refs.displayImageInput.$el.setCustomValidity(
              "Please upload a PNG, JPG, or JPEG image file."
            );
            this.$refs.displayImageInput.$el.reportValidity();
          }
        } else {
          if (this.$refs.displayImageInput) {
            this.$refs.displayImageInput.$el.setCustomValidity("");
          }
        }
      }
    },

    async submit() {
      try {
        const formData = new FormData();
        formData.append("name", this.name);
        formData.append("location", this.location);
        formData.append("description", this.description);
        formData.append("status", this.status);
        formData.append("image", this.files);
        formData.append("display_image", this.displayFiles);
        formData.append("created_by", this.getLoggedInUser.id);
        formData.append("updated_by", this.getLoggedInUser.id);

        const res = await this.createHospital(formData);
        if (res.status === 200) {
          this.$swal({
            title: "Hospital created successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("hospital-create-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.location = "";
      this.description = "";
      this.files = "";
      this.status = "";
      this.displayFiles = null;
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
