<template>
  <b-modal
    id="hospital-edit-modal"
    title="Hospital Edit Modal"
    centered
    hide-footer
    size="lg"
    @hidden="reset"
    :no-close-on-esc="true"
    :no-close-on-backdrop="true"
  >
    <template #modal-title>
      <h2 class="m-0">Hospital Edit</h2>
    </template>
    <validation-observer ref="hospitalEditFormValidation">
      <b-form @submit.prevent="submit">
        <b-form-group label-for="name">
          <template #label>
            Hospital Name <span class="text-danger">*</span>
          </template>
          <b-form-input
            id="name"
            v-model="name"
            v-restrict="regex"
            required
            placeholder="Hospital Name"
            key="name"
            @keydown="checkMaxLength($event, key, 200)"
          />
        </b-form-group>

        <b-form-group label-for="location">
          <template #label>
            Location <span class="text-danger">*</span>
          </template>
          <b-form-textarea
            id="intro"
            v-model="location"
            placeholder="Location"
            rows="3"
            max-rows="6"
            key="location"
            @keydown="checkMaxLength($event, key, 1000)"
          ></b-form-textarea>
        </b-form-group>

        <b-form-group label-for="description">
          <template #label> Description </template>
          <quill-editor id="description" v-model="description" />
        </b-form-group>

        <b-form-group label-for="status">
          <template #label>
            Status <span class="text-danger">*</span>
          </template>
          <b-form-select
            id="status"
            v-model="status"
            :options="options"
          ></b-form-select>
        </b-form-group>

        <b-form-group label-for="image">
          <template #label> Image <span class="text-danger">*</span> </template>
          <div v-if="hospital && hospital.image" class="mb-2">
            <img
              :src="hospital.image"
              alt="Hospital Image"
              class="img-thumbnail"
              style="max-width: 15%; margin-bottom: 10px"
            />
          </div>
          <validation-provider v-slot="{ errors }" name="Image">
            <b-form-group
              class="form-group"
              id="input-group-2"
              label-for="input-2"
              description="Upload an image in PNG, JPG, or JPEG format."
              :invalid-feedback="errors[0]"
            >
              <b-form-file
                ref="imageInput"
                id="documents"
                placeholder="Choose a PNG, JPG, or JPEG file or drop it here..."
                drop-placeholder="Drop file here..."
                accept=".png,.jpg,.jpeg"
                plain
                v-model="files"
                @change="validateFileType($event, 'files')"
              ></b-form-file>
            </b-form-group>
          </validation-provider>
        </b-form-group>

        <!-- Existing Banner Image -->
        <b-form-group
          class="form-group"
          label="Existing Banner Image:"
          label-for="existingBannerImage"
        >
          <img
            v-if="hospital && hospital.display_image"
            :src="hospital.display_image"
            alt="Existing Banner Image"
            style="max-width: 10%; margin-bottom: 10px"
          />
        </b-form-group>

        <!-- File Input for Banner Image -->
        <validation-provider v-slot="{ errors }" name="DisplayImage">
          <b-form-group
            class="form-group"
            id="input-group-2"
            label-for="input-2"
            description="Upload an image in PNG, JPG, or JPEG format."
            :invalid-feedback="errors[0]"
          >
            <template #label>
              Banner Image <span class="text-danger">*</span>
            </template>
            <b-form-file
              ref="displayImageInput"
              id="displayDocuments"
              placeholder="Choose a PNG, JPG, or JPEG file or drop it here..."
              drop-placeholder="Drop file here..."
              accept=".png,.jpg,.jpeg"
              plain
              v-model="displayFiles"
              @change="validateFileType($event, 'displayFiles')"
            ></b-form-file>
          </b-form-group>
        </validation-provider>

        <b-form-group class="mt-2 text-right">
          <b-button type="submit" variant="info" pill class="mr-1">
            Update
          </b-button>
        </b-form-group>
      </b-form>
    </validation-observer>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import util from "@/util.js";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    quillEditor,
  },
  mixins: [util],
  props: {
    hospital: Object,
  },
  data() {
    return {
      required,
      name: "",
      location: "",
      description: "",
      files: null,
      displayFiles: null,
      status: "",
      options: [
        { value: "", text: "-- Select --" },
        { value: true, text: "Enable" },
        { value: false, text: "Disable" },
      ],
      regex: /^[a-zA-Z ]*$/,
    };
  },
  async mounted() {
    if (this.hospital) {
      this.name = this.hospital.name;
      this.description = this.hospital.description;
      this.location = this.hospital.location;
      this.status = this.hospital.status;
    }
  },
  methods: {
    ...mapActions({
      updateHospital: "appData/updateHospital",
    }),
    checkMaxLength(event, fieldName, maxLength) {
      if (
        event.target.value.length >= maxLength &&
        event.key !== "Backspace" &&
        event.key !== "Delete"
      ) {
        event.preventDefault();
        this[fieldName] = event.target.value.slice(0, maxLength);
      }
    },
    validateFileType(event) {
      const file = event.target.files[0];
      if (!file) return;

      if (fileType === "files") {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          event.target.value = null;
          if (this.$refs.imageInput) {
            this.$refs.imageInput.$el.setCustomValidity(
              "Please upload a PNG, JPG, or JPEG image file."
            );
            this.$refs.imageInput.$el.reportValidity();
          }
        } else {
          if (this.$refs.imageInput) {
            this.$refs.imageInput.$el.setCustomValidity("");
          }
        }
      } else if (fileType === "displayFiles") {
        const allowedTypes = ["image/png", "image/jpeg", "image/jpg"];
        if (!allowedTypes.includes(file.type)) {
          event.target.value = null;
          if (this.$refs.displayImageInput) {
            this.$refs.displayImageInput.$el.setCustomValidity(
              "Please upload a PNG, JPG, or JPEG image file."
            );
            this.$refs.displayImageInput.$el.reportValidity();
          }
        } else {
          if (this.$refs.displayImageInput) {
            this.$refs.displayImageInput.$el.setCustomValidity("");
          }
        }
      }
    },
    async submit() {
      const formData = new FormData();
      formData.append("name", this.name);
      formData.append("location", this.location);
      formData.append("description", this.description);
      formData.append("status", this.status);
      if (this.files) {
        formData.append("image", this.files);
      }
      if (this.displayFiles) {
        formData.append("display_image", this.displayFiles);
      }

      formData.append("updated_by", this.getLoggedInUser.id);

      try {
        const res = await this.updateHospital({
          payload: formData,
          pk: this.hospital.id,
        });
        if (res.status === 200) {
          this.$swal({
            title: "Hospital updated successfully",
            icon: "success",
          });
          this.reset();
          this.$nextTick(() => {
            this.$bvModal.hide("hospital-edit-modal");
          });
          this.$emit("modalClosed");
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    reset() {
      this.name = "";
      this.location = "";
      this.description = "";
      this.files = "";
      this.status = "";
      this.displayFiles = null;
    },
  },
  computed: {
    ...mapGetters({ getLoggedInUser: "appData/getLoggedInUser" }),
  },
};
</script>

<style></style>
